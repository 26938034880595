<template>
  <v-row>
    <v-dialog 
    v-model="showHashtag"
    persistent
    max-width="800px"
    v-if="resolutionScreen >= 500">
        <v-card>
            <v-card-text class="pa-0">
                <v-list class=" pa-0">
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title style="font-size:large;" class="font-weight-bold">
                           {{ $t("hashtag_manual_header") }}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon size="35" dense dark color="#B7B7B7">mdi-help-circle</v-icon>
                    </v-list-item-action>
                    </v-list-item>
                </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="pa-5">
                    <div class="text-descrip">
                        <span class="font-weight-bold">{{ $t("hashtag_manual_body_1") }} </span><br /> <br />
                        <v-icon color="error">mdi-circle-slice-8</v-icon> <span>{{ $t("hashtag_manual_body_2") }} </span> <br /> <br />
                        <v-icon color="#7676a7">mdi-circle-slice-8</v-icon> <span>{{ $t("hashtag_manual_body_3") }} </span> <br />
                    </div>
                    <div>
                    <v-img
                      :src="require('@/assets/img/keyboard2_new.jpeg')"
                      max-width="700"
                      max-height="350"
                      class="mx-auto"
                    />
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="red"
                outlined
                dark
                @click="$emit('closedialog')"
                >{{ $t("balloon.close") }}</v-btn
                >
                </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog 
    v-model="showHashtag" 
    :max-width="maxWidthOnMobile"
    scrollable
    persistent
    v-else>
      <v-card class="pb-5">
        <v-card-text class="pa-5">
            <v-layout class="pt-2">
            <v-flex d-flex justify-center class="ml-6 pr-4">
                <span :style="headerPage" class="text-center"> {{ $t("hashtag_manual_header_mobile") }} </span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
                <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('closedialog')"
                >mdi-close</v-icon
                >
            </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-text class="pa-5 pb-4" style="height: 550px;">
              <div class="text-descrip-mobile pb-4">
                  <span class="font-weight-bold">{{ $t("hashtag_manual_body_1") }} </span><br /> <br />
                  <v-icon color="error">mdi-circle-slice-8</v-icon> <span>{{ $t("hashtag_manual_body_2") }} </span> <br /> <br />
                  <v-icon>mdi-circle-slice-8</v-icon> <span>{{ $t("hashtag_manual_body_3") }} </span> <br />
              </div>
              <v-tabs>
                <v-tab>IOS</v-tab>
                <v-tab>Android</v-tab>
                <v-tab-item>
                  <v-img
                  :src="require('@/assets/img/ios.jpg')"
                  max-width="350"
                  max-height="400"
                  class="mx-auto mt-5"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-img
                  :src="require('@/assets/img/android.jpg')"
                  max-width="350"
                  max-height="400"
                  class="mx-auto mt-5"
                  />
                </v-tab-item>
              </v-tabs>
              
        </v-card-text>
        
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
    props: ["showHashtag"],
    data: function (){
        return {
            show: false,
        }
    },
    computed: {
        ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
        ...mapState({ processloader: "loading" }),
        ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        ]),
        resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    }
}
</script>

<style scoped>
  .inner-circle{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    line-height: 50px;
    border-color: blue;
    padding: 10px 18px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    position: absolute;
    right: 9%;
    top: 59%;
  }
 .text-descrip span {
    font-size: 16px;
 }

 .text-descrip-mobile span {
    font-size: 14px;
 }
</style>